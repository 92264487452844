import { useCallback, useEffect, useState } from "react";

import { Card, Divider, Grid, List, ListItem, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import utc from "dayjs/plugin/utc";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import CustomViewField from "../../components/CustomViewField";
import LoadingIndicator from "../../components/LoadingIndicator";
import CustomAutocompleteNew from "../../components/TextFields/CustomAutocompleteNew";
import CustomDatepickerNew from "../../components/TextFields/CustomDatepickerNew";
import CustomTextFieldNew from "../../components/TextFields/CustomTextFieldNew";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";
import { TitleStack } from "../../theme/standarts_styles";

dayjs.extend(utc);
dayjs.extend(isBetween);

const CompensationView = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const { compensationId } = useParams();
  const { compensation } = useSelector((state) => state.paymentsReducer);
  const { setCompensation } = useActions();

  const methods = useForm({
    mode: "onBlur",
    defaultValues: { payment_period: compensation?.payment_period },
  });

  const {
    handleSubmit,
    setError,
    formState: { isSubmitSuccessful },
    reset,
    clearErrors,
    control,
  } = methods;

  useEffect(() => {
    reset({ payment_period: compensation?.payment_period });
  }, [reset, compensation]);

  const getColorByStatus = (value) => {
    switch (value) {
      case "Новое":
        return "#414bb2";
      case "Согласовано":
      case "Выплачено":
        return "#8fd14f";
      case "Отклонено":
      case "Аннулировано":
        return "#f24726";
      case "Отозвано":
      case "Черновик":
      case "Черновик (Изменено)":
        return "#828282";
      default:
        return "#000000";
    }
  };

  const onSubmit = async ({ payment_period, ...data }) => {
    setLoading(true);

    const newData = {
      ...compensation,
      ...data,
      payment_period: payment_period
        ? dayjs(payment_period).format("YYYY-MM")
        : null,
      ...(compensation.checks.length > 0 && {
        checks: compensation.checks.map((file) => file.id),
      }),
      ...(compensation.photos.length > 0 && {
        photos: compensation.photos.map((file) => file.id),
      }),
    };

    try {
      await $authHost.put(`/compensation/${compensationId}/update/`, newData);
    } catch (e) {
      setError("root.serverError", {
        type: "server",
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const onChangeStatus = async (status) => {
    setLoading(true);

    const newData = {
      ...compensation,
      ...(compensation.checks.length > 0 && {
        checks: compensation.checks.map((file) => file.id),
      }),
      ...(compensation.photos.length > 0 && {
        photos: compensation.photos.map((file) => file.id),
      }),
      status,
    };

    try {
      await $authHost.put(`/compensation/${compensationId}/update/`, newData);
      handleBack();
    } catch (e) {
      setError("root.serverError", {
        type: "server",
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate("/payments/compensations");
    }
  }, [isSubmitSuccessful, navigate]);

  const handleBack = () => {
    navigate(`/payments/compensations`);
  };

  const getHistory = useCallback(async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(
        `/compensation/history/all/?compensation_id=${compensationId}`,
      );
      const data = response.data.items;
      setHistory(data);
    } catch (e) {
      console.log(e);
    }
  }, [compensationId]);

  const getMyCompensation = useCallback(async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(`/compensation/${compensationId}/`);
      const data = response.data;
      setCompensation(data);
    } catch (e) {
      console.log(e);
    }
  }, [setCompensation, compensationId]);

  useEffect(() => {
    Promise.all([getMyCompensation(), getHistory()]).then(() =>
      setLoading(false),
    );
  }, [getHistory, getMyCompensation]);

  const paymentPeriod = useWatch({ control, name: "payment_period" });

  useEffect(() => {
    const paymentPeriodIsBetween = dayjs(paymentPeriod).isBetween(
      compensation.expenses_period,
      dayjs(compensation.expenses_period).add(2, "month"),
      "month",
      "[]",
    );

    if (!paymentPeriodIsBetween) {
      setError("payment_period", {
        type: "custom",
        message:
          "Период выплаты не может быть раньше периода расходов или превышать его более чем на 2 месяца. Пожалуйста, выберите корректный период",
      });
    } else {
      clearErrors("payment_period");
    }
  }, [clearErrors, compensation.expenses_period, paymentPeriod, setError]);

  return (
    <>
      <TitleStack>
        <Typography sx={{ my: 2 }} variant="h5">
          Заявка
        </Typography>
      </TitleStack>
      <Card sx={{ p: 3 }}>
        {loading && <LoadingIndicator />}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Typography sx={{ pb: 2 }} variant="h6">
                  Информация:
                </Typography>
                <CustomViewField
                  label="Вид компенсации"
                  text={compensation?.type}
                />
                <CustomViewField
                  label="Сотрудник"
                  text={compensation?.user?.display_name}
                />
                <CustomViewField
                  label="Отдел"
                  text={compensation?.department?.name}
                />
                <CustomViewField label="Валюта" text={compensation?.currency} />
                <CustomViewField
                  label="Сумма в валюте"
                  text={compensation?.amount}
                />
                <CustomViewField
                  label="Период расходов"
                  text={
                    compensation?.expenses_period
                      ? dayjs(compensation?.expenses_period).format("MMMM YYYY")
                      : ""
                  }
                />
                {compensation?.status === "Новое" ? (
                  <CustomDatepickerNew
                    format="MMMM YYYY"
                    label="Период выплаты"
                    name="payment_period"
                    required
                    views={["year", "month"]}
                  />
                ) : (
                  <CustomViewField
                    label="Период выплаты"
                    text={
                      compensation?.payment_period
                        ? dayjs(compensation?.payment_period).format(
                            "MMMM YYYY",
                          )
                        : ""
                    }
                  />
                )}
                <CustomViewField
                  files={compensation?.checks}
                  label="Чек"
                  type="file"
                />
                <CustomViewField
                  files={compensation?.photos}
                  label="Фото-отчет"
                  type="file"
                />
                <CustomViewField
                  label="Текст запроса"
                  text={compensation?.request_text}
                />
                <Divider sx={{ mb: 3 }} />
                {compensation?.status === "Новое" && (
                  <CustomAutocompleteNew
                    label="Статус"
                    name="status"
                    options={["Согласовано", "Отклонено"]}
                    required
                  />
                )}
                {compensation?.status === "Новое" && (
                  <CustomTextFieldNew
                    label="Комментарий"
                    multiline
                    name="comment"
                    required
                    rows={3}
                  />
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography sx={{ pb: 2 }} variant="h6">
                  История:
                </Typography>
                <Stack>
                  {history.map((i) => (
                    <List key={i.updated_at} sx={{ listStyleType: "disc" }}>
                      <ListItem
                        sx={[
                          { display: "list-item" },
                          {
                            "&::marker": { color: getColorByStatus(i.status) },
                          },
                        ]}
                      >
                        <Stack>
                          <Typography variant="body2">
                            {dayjs(i.updated_at)
                              .utcOffset(5)
                              .format("DD.MM.YYYY HH:mm")}
                          </Typography>
                          <Typography sx={{ pb: 1 }}>
                            {i.user_approved.display_name}
                          </Typography>
                          <Typography
                            sx={{ color: getColorByStatus(i.status) }}
                          >
                            {i.status}
                          </Typography>
                          {i.comment && (
                            <Typography sx={{ pt: 1 }}>
                              Комментарий: {i.comment}
                            </Typography>
                          )}
                        </Stack>
                      </ListItem>
                    </List>
                  ))}
                </Stack>
              </Grid>
            </Grid>
            <Stack direction="row" spacing={2}>
              {compensation?.status === "Новое" && (
                <Button type="submit" variant="contained">
                  Сохранить
                </Button>
              )}
              {compensation?.status === "Согласовано" && (
                <>
                  <Button
                    onClick={() => onChangeStatus("Выплачено")}
                    variant="contained"
                  >
                    Выплачено
                  </Button>
                  <Button
                    onClick={() => onChangeStatus("Аннулировано")}
                    variant="outlined"
                  >
                    Аннулировать
                  </Button>
                </>
              )}
              <Button color="primary" onClick={handleBack} variant="text">
                Назад
              </Button>
            </Stack>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default CompensationView;
