import React from "react";

import {
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import ApproveSchedule from "../components/ApproveSchedule";
import CompanySchedule from "../components/Schedule/CompanySchedule";
import MySchedule from "../components/Schedule/MySchedule";
import DynamicTabs from "../components/Tabs/DynamicTabs";
import RemoteDynamicTabs from "../components/Tabs/RemoteDynamicTabs";
import WorkSchedule from "../components/workSchedule";
import { TitleStack } from "../theme/standarts_styles";

const Schedule = () => {
  const { t, i18n } = useTranslation();
  const { roles } = useSelector((state) => state.authReducer);
  const location = useLocation();

  const tabs = [
    { label: "Мое расписание", path: "my-schedule" },
    { label: "Мои заявления", path: "my-statements" },
    ...(roles?.some(
      (role) => role === "ERP_Admins" || role === "ERP_ReportCardAdmins",
    )
      ? [{ label: "Согласование", path: "statements-approval" }]
      : []),
  ];

  // if (!tabs.some((tab) => location.pathname.endsWith(tab.path)) &&
  // 	!location.pathname.endsWith('new'))
  // {
  // 	let redirectPath = location.pathname;
  // 	if (!location.pathname.endsWith('/')) {
  // 		redirectPath += '/';
  // 	}
  // 	redirectPath += tabs[0].path;
  // 	return <Navigate to={redirectPath} />;
  // }

  return (
    <Container>
      <TitleStack>
        <Typography sx={{ mr: 4 }} variant="h4">
          Расписание
        </Typography>
      </TitleStack>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <RemoteDynamicTabs tabs={tabs} />
          <Outlet /> {/* Отображение содержимого дочерних маршрутов */}
        </Box>
      </Box>
    </Container>
  );
};

export default Schedule;
