import { useState } from "react";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { $authHost } from "../../http";

const CustomFileInput = ({
  label,
  helperText,
  files,
  setFiles,
  error,
  setLoading,
}) => {
  const { t } = useTranslation();

  const [confirmationId, setConfirmationId] = useState(false);

  const onUploadFile = async (files) => {
    setLoading(true);

    try {
      let requests = Object.values(files).map((file) => {
        return $authHost.post(
          "/compensation/upload_file/",
          { file },
          {
            headers: { "Content-Type": "multipart/form-data" },
          },
        );
      });

      const response = await Promise.allSettled(requests);

      setFiles((prevState) => [
        ...prevState,
        ...response
          .filter((r) => r?.status === "fulfilled")
          .map((r) => r?.value?.data),
      ]);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const onDeleteFile = async (id) => {
    setLoading(true);

    try {
      await $authHost.delete(`/compensation/me/delete_file/${id}/`);

      setFiles((prevState) => {
        return prevState.filter((file) => file.id !== id);
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box mb={2}>
      <Box
        px={2}
        sx={{
          // TODO: change color?
          border: `1px solid ${error ? "#FF4842" : "#dce0e4"}`,
          borderRadius: 1,
          position: "relative",
          "&:hover": { borderColor: "#000000" },
        }}
      >
        <Typography
          sx={{
            position: "absolute",
            top: "-1px",
            left: "8px",
            padding: "0 5px",
            transform: "translateY(-50%)",
            color: error ? "#FF4842" : "#637381",
            fontSize: "12px",
            backgroundColor: "#fff",
          }}
        >
          {label}
        </Typography>
        <Button
          component="label"
          startIcon={<CloudUploadIcon />}
          sx={{ marginTop: 2, marginBottom: 2 }}
          variant="contained"
        >
          Прикрепить файл
          <input
            hidden
            multiple
            onChange={(event) => onUploadFile(event.target.files)}
            type="file"
          />
        </Button>
        {files.map((file) => (
          <Box
            key={file.id}
            my={2}
            p={1}
            // TODO: change color?
            sx={{ border: "1px solid #dce0e4", borderRadius: 1 }}
          >
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              spacing={2}
            >
              <Stack alignItems="center" direction="row" spacing={1}>
                <FilePresentIcon />
                <Typography>{file.display_name}</Typography>
              </Stack>
              {confirmationId === file.id ? (
                <Stack direction="row">
                  <Tooltip title="Подтвердить">
                    <IconButton
                      color="inherit"
                      onClick={() => onDeleteFile(file.id)}
                      size="small"
                    >
                      <CheckIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Отмена">
                    <IconButton
                      color="inherit"
                      onClick={() => setConfirmationId(null)}
                      size="small"
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              ) : (
                <Tooltip title="Удалить">
                  <IconButton
                    color="inherit"
                    onClick={() => setConfirmationId(file.id)}
                    size="small"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </Box>
        ))}
      </Box>
      {helperText && (
        // TODO: change color?
        <Typography
          color={error ? "#FF4842" : "#454F5B"}
          fontSize={12}
          mt="4px"
          mx="14px"
        >
          {helperText}
        </Typography>
      )}
    </Box>
  );
};

CustomFileInput.propTypes = {
  label: PropTypes.string.isRequired,
  files: PropTypes.array.isRequired,
  setFiles: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  setLoading: PropTypes.func.isRequired,
};

export default CustomFileInput;
