import React from "react";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";

const CustomMultiAutocompleteForObjectLocalized = ({
  name,
  label,
  options,
  rules,
  multiple = false,
  onChangeValue,
  size, helperText,

  ...rest
}) => {
  const { control, formState } = useFormContext();

  const error = formState.errors[name];
  const req = !!rules?.required;

  return (
      <div style={{position: "relative", marginBottom: "12px", width: "100%"}}>
          <Controller
              control={control}
              name={name}
              render={({field: {onChange, value}}) => (
                  <Autocomplete
                      disabled={!!rest.disabled}
                      getOptionLabel={(option) => option.name}
                      id={name}
                      multiple={multiple}
                      onChange={(_, selectedOptions) => {
                          onChange(
                              multiple
                                  ? selectedOptions || []
                                  : selectedOptions?.original_name || null,
                          );
                          onChangeValue(
                              multiple
                                  ? selectedOptions || []
                                  : selectedOptions?.original_name || null,
                          );
                      }}
                      options={options}
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              {...rest}
                              FormHelperTextProps={{
                                  style: {
                                      fontSize: '12px',
                                      fontStyle: 'italic'
                                  }
                              }}
                              InputLabelProps={{
                                  shrink: true,
                              }}
                              error={!!error}
                              helperText={helperText}
                              label={`${label}${req ? " *" : ""}`}
                              size={size}
                              variant="outlined"
                          />
                      )}
                      size={size}
                      value={
                          multiple
                              ? options && value
                                  ? options.filter((option) =>
                                      value.some(
                                          (val) => val.original_name === option.original_name,
                                      ),
                                  )
                                  : []
                              : (options &&
                                  value &&
                                  options.find((option) => option.original_name === value)) ||
                              null
                      }
                  />
              )}
              rules={rules}
          />
          <span
              style={{
                  position: "absolute",
                  bottom: "4px",
                  fontSize: "12px",
                  color: "red",
                  left: 14,
              }}
          >
              {error && (error?.message || "Unknown error!")}
          </span>
      </div>
  );
};

export default CustomMultiAutocompleteForObjectLocalized;
