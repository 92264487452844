import { useCallback, useEffect, useState } from "react";

import {
  Autocomplete,
  Button,
  Card,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import BlockFormModal from "../../components/BlockFormModal";
import LoadingIndicator from "../../components/LoadingIndicator";
import CustomTextField from "../../components/TextFields/CustomTextField";
import { $authHost } from "../../http";
import { TitleStack } from "../../theme/standarts_styles";
import { convertToNull } from "../../utils/convertToNull";

dayjs.extend(isSameOrBefore);

const EmploymentAdd = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const methods = useForm({
    mode: "onBlur",
    defaultValues: {
      start_date: null,
    },
  });

  const {
    handleSubmit,
    reset,
    setError,
    control,
    resetField,
    formState: { isDirty, isSubmitSuccessful },
  } = methods;

  const handleReset = () => {
    reset();
  };

  const onSubmit = async ({ start_date, ...data }) => {
    setLoading(true);

    const newData = {
      ...data,
      start_date: dayjs(start_date).format("YYYY-MM-DD"),
      status: "Новое",
    };

    try {
      await $authHost.post(`/employments/add/`, convertToNull(newData));
    } catch (e) {
      setError("root.serverError", {
        type: "server",
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate("/employee-relations/employments");
    }
  }, [isSubmitSuccessful, navigate]);

  const handleBack = () => {
    navigate(`/employee-relations/employments`);
  };

  const country = useWatch({ control, name: "country" });

  const companies = useCallback(() => {
    if (country === "Россия" || country === "Беларусь") {
      return ['ООО "АСПЭКС"', 'ООО "WHITE DATA"'];
    } else if (country === "Казахстан") {
      return ['ТОО "АСПЭКС ГРУПП"'];
    } else {
      return [];
    }
  }, [country]);

  useEffect(() => {
    resetField("company_name");
  }, [country, resetField, setError]);

  return (
    <>
      <TitleStack>
        <Typography sx={{ my: 2 }} variant="h5">
          Новое трудоустройство
        </Typography>
      </TitleStack>
      <Card sx={{ p: 3 }}>
        {loading && <LoadingIndicator />}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CustomTextField
              helperText="Введите Имя и Фамилию сотрудника на латинице"
              label="Сотрудник"
              name="employee"
              registerOptions={{
                required: t("main.another.requiredField"),
                pattern: {
                  value: /^[^\u0400-\u04FF]*$/,
                  message: "Введите Имя и Фамилию сотрудника на латинице",
                },
              }}
              size="small"
              sx={{ width: "100%", mb: 3 }}
            />
            <CustomTextField
              label="Должность"
              name="occupation"
              registerOptions={{
                required: t("main.another.requiredField"),
              }}
              size="small"
              sx={{ width: "100%", mb: 3 }}
            />
            <CustomTextField
              label="Корпоративный email"
              name="email"
              registerOptions={{
                required: t("main.another.requiredField"),
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@aspex\.kz$/,
                  message:
                    "Ошибка: Неверный домен. Убедитесь, что адрес электронной почты заканчивается на @aspex.kz",
                },
              }}
              size="small"
              sx={{ width: "100%", mb: 3 }}
            />
            <Controller
              control={control}
              name="start_date"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    control={control}
                    format="DD.MM.YYYY"
                    label="Дата выхода на работу *"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    slotProps={{
                      textField: {
                        error: !!error,
                        helperText: error?.message,
                        size: "small",
                        InputLabelProps: {
                          shrink: true,
                        },
                      },
                    }}
                    sx={{
                      width: "100%",
                      mb: 3,
                    }}
                    value={dayjs(value)}
                  />
                </LocalizationProvider>
              )}
              rules={{
                required: t("main.another.requiredField"),
              }}
            />
            <Controller
              control={control}
              name="country"
              render={({ field: { ref, ...field }, fieldState: { error } }) => {
                return (
                  <Autocomplete
                    {...field}
                    getOptionLabel={(option) => option}
                    onChange={(e, value) => field.onChange(value)}
                    options={["Россия", "Беларусь", "Казахстан"]}
                    renderInput={(params) => (
                      <TextField
                        label="Страна трудоустройства *"
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={!!error}
                        helperText={error?.message}
                        inputRef={ref}
                      />
                    )}
                    size="small"
                    sx={{
                      mb: 3,
                    }}
                  />
                );
              }}
              rules={{
                required: t("main.another.requiredField"),
              }}
            />
            <Controller
              control={control}
              name="company_name"
              render={({ field: { ref, ...field }, fieldState: { error } }) => {
                return (
                  <Autocomplete
                    {...field}
                    getOptionLabel={(option) => option}
                    onChange={(e, value) => field.onChange(value)}
                    options={companies()}
                    renderInput={(params) => (
                      <TextField
                        label="Юр. Лицо *"
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={!!error}
                        helperText={error?.message}
                        inputRef={ref}
                      />
                    )}
                    size="small"
                    sx={{
                      mb: 3,
                    }}
                  />
                );
              }}
              rules={{
                required: t("main.another.requiredField"),
              }}
            />
            <Controller
              control={control}
              name="employment_type"
              render={({ field: { ref, ...field }, fieldState: { error } }) => {
                return (
                  <Autocomplete
                    {...field}
                    getOptionLabel={(option) => option}
                    onChange={(e, value) => field.onChange(value)}
                    options={["Трудоустройство", "Самозанятость", "ИП"]}
                    renderInput={(params) => (
                      <TextField
                        label="Форма трудоустройства *"
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={!!error}
                        helperText={error?.message}
                        inputRef={ref}
                      />
                    )}
                    size="small"
                    sx={{
                      mb: 3,
                    }}
                  />
                );
              }}
              rules={{
                required: t("main.another.requiredField"),
              }}
            />
            <Stack direction="row" justifyContent="space-between">
              <Grid item>
                <Button type="submit" variant="contained">
                  Сохранить
                </Button>
                <Button
                  color="primary"
                  onClick={handleBack}
                  sx={{ ml: 1 }}
                  variant="text"
                >
                  Назад к списку
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  onClick={handleReset}
                  sx={{ ml: 1 }}
                  variant="text"
                >
                  Сбросить
                </Button>
              </Grid>
            </Stack>
          </form>
        </FormProvider>
        <BlockFormModal when={isDirty && !isSubmitSuccessful} />
      </Card>
    </>
  );
};

export default EmploymentAdd;
