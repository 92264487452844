import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";

import RemoteDynamicTabs from "../../components/Tabs/RemoteDynamicTabs";
import { TitleStack } from "../../theme/standarts_styles";

const ContractTabs = () => {
  const location = useLocation();
  const { roles } = useSelector((state) => state.authReducer);

  const tabs = [
    { label: "Договоры", path: "contracts" },
    ...(roles?.some((role) => role === "ERP_Admins")
      ? [{ label: "Трудоустройство", path: "employments" }]
      : []),
  ];

  return (
    <Container sx={{ pb: 2 }}>
      <TitleStack>
        <Typography variant="h4">Договоры</Typography>
      </TitleStack>
      <RemoteDynamicTabs tabs={tabs} />
      <Outlet /> {/* Отображение содержимого дочерних маршрутов */}
    </Container>
  );
};

export default ContractTabs;
