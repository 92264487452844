import { useCallback, useEffect, useState } from "react";

import {
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  Tooltip,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import CustomViewField from "../../components/CustomViewField";
import LoadingIndicator from "../../components/LoadingIndicator";
import CustomAutocompleteNew from "../../components/TextFields/CustomAutocompleteNew";
import CustomTextField from "../../components/TextFields/CustomTextField";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";
import { TitleStack } from "../../theme/standarts_styles";

const statusOptions = ["Доступ настроен"];

dayjs.extend(utc);

const EmploymentView = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [history, setHistory] = useState([]);
  const { employmentId } = useParams();
  const { employment } = useSelector((state) => state.contractsReducer);
  const { setEmployment } = useActions();

  const methods = useForm({
    mode: "onBlur",
  });

  const {
    handleSubmit,
    setError,
    formState: { isSubmitSuccessful },
  } = methods;

  const getColorByStatus = (value) => {
    switch (value) {
      case "Новое":
        return "#414bb2";
      case "Доступ настроен":
        return "#8fd14f";
      case "Отозвано":
        return "#f24726";
      default:
        return "#808080";
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      if (cancelConfirm) {
        await $authHost.post(`/employments/${employmentId}/cancel`, {
          comment: data.comment,
        });
      } else {
        await $authHost.put(`/employments/${employmentId}/update/`, {
          status: data.status,
        });
      }
    } catch (e) {
      setError("root.serverError", {
        type: "server",
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate("/employee-relations/employments");
    }
  }, [isSubmitSuccessful, navigate]);

  const handleBack = () => {
    navigate(`/employee-relations/employments`);
  };

  const getHistory = useCallback(async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(
        `/employments/history/all/?employment_id=${employmentId}`,
      );
      const data = response.data.items;
      setHistory(data);
    } catch (e) {
      console.log(e);
    }
  }, [employmentId]);

  const getEmployment = useCallback(async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(`/employments/${employmentId}/`);
      const data = response.data;
      setEmployment(data);
    } catch (e) {
      console.log(e);
    }
  }, [setEmployment, employmentId]);

  useEffect(() => {
    Promise.all([getEmployment(), getHistory()]).then(() => setLoading(false));
  }, [getHistory, getEmployment]);

  return (
    <>
      <TitleStack>
        <Typography sx={{ my: 2 }} variant="h5">
          Трудоустройство ({employment?.employee})
        </Typography>
      </TitleStack>
      <Card sx={{ p: 3 }}>
        {loading && <LoadingIndicator />}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Typography sx={{ pb: 2 }} variant="h6">
                  {employment?.status !== "Отозвано"
                    ? "Действующее"
                    : "Отозванное"}{" "}
                  трудоустройство:
                </Typography>
                <CustomViewField
                  label="Дата создания"
                  text={dayjs(employment?.created_at).format("DD.MM.YYYY")}
                />
                <CustomViewField
                  label="Сотрудник"
                  text={employment?.employee}
                />
                <CustomViewField
                  label="Должность"
                  text={employment?.occupation}
                />
                <CustomViewField label="Email" text={employment?.email} />
                <CustomViewField
                  label="Дата выхода на работу"
                  text={dayjs(employment?.start_date).format("DD.MM.YYYY")}
                />
                <CustomViewField
                  label="Страна трудоустройства"
                  text={employment?.country}
                />
                <CustomViewField
                  label="Юр. лицо"
                  text={employment?.company_name}
                />
                <CustomViewField
                  label="Форма трудоустройства"
                  text={employment?.employment_type}
                />
                <Divider sx={{ mb: 3 }} />
                {employment?.status === "Новое" && !cancelConfirm && (
                  <CustomAutocompleteNew
                    label="Статус"
                    name="status"
                    options={statusOptions}
                    required
                  />
                )}
                {(employment?.status === "Новое" ||
                  employment?.status === "Доступ настроен") &&
                  cancelConfirm && (
                    <CustomTextField
                      label="Комментарий"
                      multiline
                      name="comment"
                      registerOptions={{
                        required: t("main.another.requiredField"),
                      }}
                      rows={3}
                      size="small"
                      sx={{ width: "100%", mb: 3 }}
                    />
                  )}
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography sx={{ pb: 2 }} variant="h6">
                  История:
                </Typography>
                <Stack>
                  {history.map((i) => (
                    <List key={i.updated_at} sx={{ listStyleType: "disc" }}>
                      <ListItem
                        sx={[
                          { display: "list-item" },
                          {
                            "&::marker": { color: getColorByStatus(i.status) },
                          },
                        ]}
                      >
                        <Stack>
                          <Typography variant="body2">
                            {dayjs(i.updated_at)
                              .utcOffset(5)
                              .format("DD.MM.YYYY HH:mm")}
                          </Typography>
                          <Typography sx={{ pb: 1 }}>
                            {i.user_approved.display_name}
                          </Typography>
                          <Typography
                            sx={{ color: getColorByStatus(i.status) }}
                          >
                            {i.status}
                          </Typography>
                          {i.comment && (
                            <Typography sx={{ pt: 1 }}>
                              Комментарий: {i.comment}
                            </Typography>
                          )}
                        </Stack>
                      </ListItem>
                    </List>
                  ))}
                </Stack>
              </Grid>
            </Grid>
            <Stack direction="row" spacing={2}>
              {cancelConfirm ? (
                <>
                  <Button
                    onClick={() => setCancelConfirm(false)}
                    variant="contained"
                  >
                    Нет
                  </Button>
                  <Button color="primary" type="submit" variant="text">
                    Да
                  </Button>
                </>
              ) : (
                <>
                  {employment?.status === "Новое" && (
                    <Button type="submit" variant="contained">
                      Сохранить
                    </Button>
                  )}
                  {(employment?.status === "Новое" ||
                    employment?.status === "Доступ настроен") && (
                    <Tooltip title="Трудоустройство может быть отозвано, если оно в статусе “Новое” или “Доступ настроен”.">
                      <Button
                        disabled={
                          employment?.status !== "Новое" &&
                          employment?.status !== "Доступ настроен"
                        }
                        onClick={() => setCancelConfirm(true)}
                        sx={{
                          "&.Mui-disabled": {
                            pointerEvents: "auto",
                          },
                        }}
                        variant="outlined"
                      >
                        Отозвать
                      </Button>
                    </Tooltip>
                  )}
                  <Button color="primary" onClick={handleBack} variant="text">
                    Назад
                  </Button>
                </>
              )}
            </Stack>
            {cancelConfirm && (
              <Typography sx={{ pt: 2 }}>
                Вы уверены, что хотите аннулировать заявление?
              </Typography>
            )}
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default EmploymentView;
