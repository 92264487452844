import { useCallback, useEffect, useState } from "react";

import { Avatar, Box, Button, Card, Stack } from "@mui/material";
import Chip from "@mui/material/Chip";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Iconify from "../../components/iconify";
import Label from "../../components/label/Label";
import MaterialTablePagination from "../../components/MaterialTablePagination";
import { TableCellsWrap } from "../../components/TableCellsWrap";
import { useActions } from "../../hook/useActions";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import { getSortingString } from "../../utils/getSortingString";

const EmploymentsList = () => {
  const tableId = "employmentsList";
  const tableStates = useSelector((state) => state.tableStatesReducer);
  const isMobile = useResponsive("down", "md");

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setEmployments, selectedEmployment } = useActions();
  const { employments } = useSelector((state) => state.contractsReducer);

  const [searchText, setSearchText] = useState(
    tableStates[tableId]?.globalFilter || "",
  );
  const [pagination, setPagination] = useState({
    pageIndex: tableStates[tableId]?.pagination?.pageIndex || 0,
    pageSize: tableStates[tableId]?.pagination?.pageSize || 20,
  });
  const [sorting, setSorting] = useState(tableStates[tableId]?.sorting || []);
  const [columnFilters, setColumnFilters] = useState(
    tableStates[tableId]?.columnFilters || [],
  );
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(-1);

  const getColorByStatus = (value) => {
    switch (value) {
      case "Новое":
        return "info";
      case "Доступ настроен":
        return "success";
      case "Отозвано":
        return "default";
      default:
        return "default";
    }
  };

  const tableColumns = [
    {
      accessorKey: "updated_at",
      header: "Дата последнего изменения",
      accessorFn: (row) => dayjs(row.updated_at).format("DD.MM.YYYY") || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "status",
      header: "Статус",
      accessorFn: (row) => row.status || "",
      Cell: ({ renderedCellValue, row }) => {
        return (
          <TableCellsWrap>
            {renderedCellValue ? (
              <Label color={getColorByStatus(row.original.status)}>
                {renderedCellValue}
              </Label>
            ) : (
              <Label color="default">Не указано</Label>
            )}
          </TableCellsWrap>
        );
      },
    },
    {
      accessorKey: "employee",
      header: "Сотрудник",
      accessorFn: (row) => row.employee || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "occupation",
      header: "Должность",
      accessorFn: (row) => row.occupation || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "email",
      header: "Корпоративный email",
      accessorFn: (row) => row.email || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "start_date",
      header: "Дата выхода на работу",
      accessorFn: (row) => dayjs(row.start_date).format("DD.MM.YYYY") || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "country",
      header: "Страна трудоустройства",
      accessorFn: (row) => row.country || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "company_name",
      header: "Юр. лицо",
      accessorFn: (row) => row.company_name || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "employment_type",
      header: "Форма трудоустройства",
      accessorFn: (row) => row.employment_type || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "user_approved.display_name",
      header: "Автор последнего изменения",
      accessorFn: (row) => row.user_approved?.display_name || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    // {
    //   id: "update_author",
    //   header: "Автор последнего изменения",
    //   accessorFn: (row) =>
    //     row.update_author?.display_name ||
    //     `${row.update_author?.first_name} ${row.update_author?.last_name}`,
    //   Cell: ({ renderedCellValue, row }) => {
    //     return (
    //       <TableCellsWrap>
    //         <Chip
    //           avatar={<Avatar src={row.original.update_author?.photo} />}
    //           label={
    //             row.original.update_author?.display_name ||
    //             `${row.original.update_author?.first_name} ${row.original.update_author?.last_name}`
    //           }
    //           variant="outlined"
    //         />
    //       </TableCellsWrap>
    //     );
    //   },
    // },
    {
      accessorKey: "created_at",
      header: "Дата подачи",
      accessorFn: (row) => dayjs(row.created_at).format("DD.MM.YYYY") || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
  ];

  const handleOpenViewPage = (row) => {
    const id = row.original.id;
    if (id) {
      navigate(`/employee-relations/employments/${id}`);
      selectedEmployment(id);
    }
  };

  const handleAdd = () => {
    navigate(`/employee-relations/employments/new`);
  };

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const sortStr = getSortingString(sorting);

      const response = await $authHost.get(`/employments/all/`, {
        params: {
          page: pagination.pageIndex + 1,
          size: pagination.pageSize,
          ...(searchText && { search: searchText }),
          ...(sortStr && { sort_by: sortStr }),
          ...columnFilters.reduce(
            (acc, column) => ({
              ...acc,
              ...{ [`${column.id}_text`]: column.value },
            }),
            {},
          ),
        },
      });

      setRowCount(response.data?.total);
      setPageCount(response.data?.pages);
      setEmployments(response.data.items);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }, [
    columnFilters,
    pagination.pageIndex,
    pagination.pageSize,
    searchText,
    setEmployments,
    sorting,
  ]);

  const onGlobalFilterChange = (v) => {
    setSearchText(v);
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Box>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ height: "70px" }}
      >
        <Button
          onClick={handleAdd}
          size={isMobile ? "small" : "medium"}
          startIcon={<Iconify icon="eva:plus-fill" />}
          variant="contained"
        >
          Новое трудоустройство
        </Button>
      </Stack>
      <Card sx={{ pt: 1 }}>
        <MaterialTablePagination
          columnFilters={columnFilters}
          columns={tableColumns}
          data={employments}
          enableHiding={true}
          id={tableId}
          loading={loading}
          muiTableBodyCellProps={({ row }) => ({
            onClick: () => {
              handleOpenViewPage(row);
            },
            sx: {
              cursor: "pointer",
            },
          })}
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={onGlobalFilterChange}
          onSortingChange={setSorting}
          pageCount={pageCount}
          pagination={pagination}
          rowCount={rowCount}
          search={searchText}
          setPagination={setPagination}
          sorting={sorting}
        />
      </Card>
    </Box>
  );
};

export default EmploymentsList;
