import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  myCompensations: [],
  compensations: [],
  myCompensation: {},
  compensation: {},
};

const PaymentsSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    logOut() {
      return initialState;
    },
    setCompensations(state, { payload }) {
      state.compensations = payload;
    },
    setMyCompensations(state, { payload }) {
      state.myCompensations = payload;
    },
    setMyCompensation(state, { payload }) {
      state.myCompensation = payload;
    },
    setCompensation(state, { payload }) {
      state.compensation = payload;
    },
  },
});

export const { actions: paymentsActions, reducer: paymentsReducer } =
  PaymentsSlice;
export default PaymentsSlice.reducer;
