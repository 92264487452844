import React, { useCallback, useEffect, useState } from "react";

import {
  Button,
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  Tooltip,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import CustomViewField from "../../components/CustomViewField";
import LoadingIndicator from "../../components/LoadingIndicator";
import CustomTextField from "../../components/TextFields/CustomTextField";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";

const types = {
  vacation: "Отпуск",
  day_off: "Отгул",
  sick_leave: "Больничный",
  overtime_work_days: "Сверхурочные часы",
  fired: "Увольнение",
};

const payers = {
  company_expense: "За счет компании",
  self_expense: "За свой счет",
  overwork_expense: "За счет переработок",
};

const statuses = {
  new: { label: "Новое", color: "#414bb2" },
  approved: { label: "Согласовано", color: "#8fd14f" },
  rejected: { label: "Отклонено", color: "#f24726" },
  cancelled: { label: "Аннулировано", color: "#f24726" },
  recalled: { label: "Отозвано", color: "#f24726" },
};

const MyStatement = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [recallConfirm, setRecallConfirm] = useState(false);
  const [history, setHistory] = useState([]);
  const { statementId } = useParams();
  const navigate = useNavigate();

  const { setStatement } = useActions();

  const { statement } = useSelector((state) => state.calendarReducer);

  const methods = useForm({
    mode: "onBlur",
  });

  const {
    handleSubmit,
    setError,
    formState: { isSubmitSuccessful },
  } = methods;

  const onRecall = async (data) => {
    setLoading(true);

    try {
      await $authHost.post(`/calendar_rules/claim/${statementId}/cancel/`, {
        comment: data.comment,
      });
    } catch (e) {
      setError("root.serverError", {
        type: "server",
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate("/schedule/my-statements");
    }
  }, [isSubmitSuccessful, navigate]);

  const handleBack = () => {
    navigate(`/schedule/my-statements`);
  };

  const getHistory = useCallback(async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(
        `/calendar_rules/history/all/?calendar_rules_id=${statementId}`,
      );
      const data = response.data.items;
      setHistory(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [statementId]);

  const getStatement = useCallback(async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(`/calendar_rules/${statementId}/`);
      const data = response.data;
      setStatement(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [setStatement, statementId]);

  useEffect(() => {
    getStatement();
    getHistory();
  }, [getHistory, getStatement]);

  return (
    <Card sx={{ mt: 2, p: 3 }}>
      {loading && <LoadingIndicator />}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onRecall)}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Typography sx={{ pb: 2 }} variant="h6">
                Информация:
              </Typography>
              <CustomViewField
                label="Тип заявки"
                text={types[statement?.type]}
              />
              {statement?.type !== "fired" && (
                <CustomViewField
                  label="За чей счет"
                  text={payers[statement?.payer]}
                />
              )}
              <CustomViewField
                label="Дата создания"
                text={dayjs(statement?.created_at).format("DD.MM.YYYY")}
              />
              <CustomViewField
                label="Сотрудник"
                text={statement?.user?.display_name}
              />
              <CustomViewField
                label="Отдел"
                text={statement?.department?.name}
              />
              <CustomViewField
                label="Текст запроса"
                text={statement?.request_text}
              />
              <CustomViewField
                label="Дата начала"
                text={dayjs(statement?.start_date).format("DD.MM.YYYY")}
              />
              <CustomViewField
                label="Дата окончания"
                text={dayjs(statement?.end_date).format("DD.MM.YYYY")}
              />
              <Divider sx={{ mb: 3 }} />
              {statement?.status === "new" && (
                <CustomTextField
                  label="Комментарий"
                  multiline
                  name="comment"
                  registerOptions={{
                    required: t("main.another.requiredField"),
                  }}
                  rows={3}
                  size="small"
                  sx={{ width: "100%", mb: 3 }}
                />
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography sx={{ pb: 2 }} variant="h6">
                История:
              </Typography>
              <Stack>
                {history.map((i) => (
                  <List key={i.updated_at} sx={{ listStyleType: "disc" }}>
                    <ListItem
                      sx={[
                        { display: "list-item" },
                        { "&::marker": { color: statuses[i.status]?.color } },
                      ]}
                    >
                      <Stack>
                        <Typography variant="body2">
                          {dayjs(i.updated_at)
                            .utcOffset(5)
                            .format("DD.MM.YYYY HH:mm")}
                        </Typography>
                        <Typography sx={{ pb: 1 }}>
                          {i.user_approved.display_name}
                        </Typography>
                        <Typography sx={{ color: statuses[i.status]?.color }}>
                          {statuses[i.status]?.label}
                        </Typography>
                        {i.comment && (
                          <Typography sx={{ pt: 1 }}>
                            Комментарий: {i.comment}
                          </Typography>
                        )}
                      </Stack>
                    </ListItem>
                  </List>
                ))}
              </Stack>
            </Grid>
          </Grid>
          <Stack direction="row" spacing={2}>
            {recallConfirm ? (
              <>
                <Button
                  onClick={() => setRecallConfirm(false)}
                  variant="contained"
                >
                  Нет
                </Button>
                <Button color="primary" type="submit" variant="text">
                  Да
                </Button>
              </>
            ) : (
              <>
                <Tooltip title="Заявление может быть отозвано сотрудником, если оно в статусе “Новое” и не входит в закрытый период табеля.">
                  <Button
                    disabled={statement?.status !== "new"}
                    onClick={() => setRecallConfirm(true)}
                    sx={{
                      "&.Mui-disabled": {
                        pointerEvents: "auto",
                      },
                    }}
                    variant="contained"
                  >
                    Отозвать
                  </Button>
                </Tooltip>
                <Button color="primary" onClick={handleBack} variant="text">
                  Назад
                </Button>
              </>
            )}
          </Stack>
          {recallConfirm && (
            <Typography sx={{ pt: 2 }}>
              Вы уверены, что хотите отозвать заявление?
            </Typography>
          )}
        </form>
      </FormProvider>
    </Card>
  );
};

export default MyStatement;
