import { useEffect, useState } from "react";

import { Button, Card, Tooltip, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import BlockFormModal from "../../components/BlockFormModal";
import LoadingIndicator from "../../components/LoadingIndicator";
import CustomAutocompleteNew from "../../components/TextFields/CustomAutocompleteNew";
import CustomDatepickerNew from "../../components/TextFields/CustomDatepickerNew";
import CustomFileInput from "../../components/TextFields/CustomFileInput";
import CustomTextFieldNew from "../../components/TextFields/CustomTextFieldNew";
import { $authHost } from "../../http";
import { TitleStack } from "../../theme/standarts_styles";
import { convertToNull } from "../../utils/convertToNull";

dayjs.extend(isBetween);

const MyCompensationAdd = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [receiptFiles, setReceiptFiles] = useState([]);
  const [receiptFilesError, setReceiptFilesError] = useState(false);
  const [photoFiles, setPhotoFiles] = useState([]);
  const [photoFilesError, setPhotoFilesError] = useState(false);
  const navigate = useNavigate();

  const methods = useForm({
    mode: "onBlur",
    defaultValues: {
      type: null,
      currency: null,
      amount: "",
      expenses_period: null,
      payment_period: null,
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    formState: { isDirty, isSubmitSuccessful },
  } = methods;

  const handleReset = () => {
    reset();
  };

  useEffect(() => {
    if (receiptFiles.length > 0) {
      setReceiptFilesError(false);
    }
  }, [receiptFiles]);

  useEffect(() => {
    if (photoFiles.length > 0) {
      setPhotoFilesError(false);
    }
  }, [photoFiles]);

  const onSubmit = async (
    { expenses_period, payment_period, request_text, amount, ...data },
    draft,
  ) => {
    try {
      const newData = {
        ...data,
        expenses_period: expenses_period
          ? dayjs(expenses_period).format("YYYY-MM")
          : null,
        payment_period: payment_period
          ? dayjs(payment_period).format("YYYY-MM")
          : null,
        ...(receiptFiles.length > 0 && {
          checks: receiptFiles.map((file) => file.id),
        }),
        ...(photoFiles.length > 0 && {
          photos: photoFiles.map((file) => file.id),
        }),
        ...(amount && { amount }),
        ...(request_text && { request_text }),
      };

      if (receiptFiles.length > 0) {
        setReceiptFilesError(false);
        clearErrors("receiptFiles");
      }

      if (photoFiles.length > 0) {
        setPhotoFilesError(false);
        clearErrors("photoFiles");
      }

      if (!draft) {
        if (receiptFiles.length === 0) {
          setReceiptFilesError(true);
          setError("root.random", {
            type: "receiptFiles",
          });
        }

        if (photoFiles.length === 0) {
          setPhotoFilesError(true);
          setError("root.random", {
            type: "photoFiles",
          });
        }

        !amount &&
          setError("amount", {
            type: "custom",
            message: "Обязательное поле",
          });

        !payment_period &&
          setError("payment_period", {
            type: "custom",
            message: "Обязательное поле",
          });

        !request_text &&
          setError("request_text", {
            type: "custom",
            message: "Обязательное поле",
          });

        if (
          receiptFiles.length > 0 &&
          photoFiles.length > 0 &&
          request_text &&
          payment_period &&
          request_text
        ) {
          setLoading(true);

          await $authHost.post(
            `/compensation/me/add/`,
            convertToNull({ ...newData, status: "Новое" }),
          );
        }
      }

      if (draft) {
        setLoading(true);

        await $authHost.post(
          `/compensation/me/add/`,
          convertToNull({ ...newData, status: "Черновик" }),
        );
      }
    } catch (e) {
      setError("root.serverError", {
        type: "server",
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate("/payments/my-compensations");
    }
  }, [isSubmitSuccessful, navigate]);

  const handleBack = () => {
    navigate(`/payments/my-compensations`);
  };

  const paymentPeriod = useWatch({ control, name: "payment_period" });
  const expensesPeriod = useWatch({ control, name: "expenses_period" });

  useEffect(() => {
    if (paymentPeriod) {
      const paymentPeriodIsBetween = dayjs(paymentPeriod).isBetween(
        expensesPeriod,
        dayjs(expensesPeriod).add(2, "month"),
        "month",
        "[]",
      );

      if (!paymentPeriodIsBetween) {
        setError("payment_period", {
          type: "custom",
          message:
            "Период выплаты не может быть раньше периода расходов или превышать его более чем на 2 месяца. Пожалуйста, выберите корректный период",
        });
      } else {
        clearErrors("payment_period");
      }
    } else {
      clearErrors("payment_period");
    }
  }, [clearErrors, expensesPeriod, paymentPeriod, setError]);

  return (
    <>
      <TitleStack>
        <Typography sx={{ my: 2 }} variant="h5">
          Новая заявка
        </Typography>
      </TitleStack>
      <Card sx={{ p: 3 }}>
        {loading && <LoadingIndicator />}
        <FormProvider {...methods}>
          <form>
            <CustomAutocompleteNew
              disableClearable
              helperText='Поле "Вид компенсации" будет недоступно для изменений после сохранения заявки'
              label="Вид компенсации"
              name="type"
              options={[
                "Спорт",
                "Подарок на ДР",
                "Командировочные расходы",
                "Другое",
              ]}
              required
            />
            <CustomAutocompleteNew
              disableClearable
              helperText={
                'Поле "Валюта" будет недоступно для изменений после сохранения заявки'
              }
              label="Валюта"
              name="currency"
              options={["RUB", "KZT"]}
              required
            />
            <CustomTextFieldNew label="Сумма в валюте" name="amount" />
            <CustomDatepickerNew
              format="MMMM YYYY"
              helperText='Поле "Период расходов" будет недоступно для изменений после сохранения заявки'
              label="Период расходов"
              name="expenses_period"
              required
              views={["year", "month"]}
            />
            <CustomDatepickerNew
              format="MMMM YYYY"
              label="Период выплаты"
              name="payment_period"
              views={["year", "month"]}
            />
            <CustomFileInput
              error={receiptFilesError}
              files={receiptFiles}
              helperText='Переименуйте файл перед загрузкой "Фамилия_месяц_год_чек_номер"
                например "Иванов_окт_2024_чек_1"'
              label="Чек"
              setFiles={setReceiptFiles}
              setLoading={setLoading}
            />
            <CustomFileInput
              error={photoFilesError}
              files={photoFiles}
              helperText='Переименуйте файл перед загрузкой "Фамилия_месяц_год_фото_номер"
                например "Иванов_окт_2024_фото_1"'
              label="Фото-отчет"
              setFiles={setPhotoFiles}
              setLoading={setLoading}
            />
            <CustomTextFieldNew
              label="Текст запроса"
              multiline
              name="request_text"
              rows={3}
            />
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" spacing={2}>
                <Tooltip title="Черновики заявок доступны для просмотра ТОПам, но без возможности их согласования">
                  <Button
                    onClick={handleSubmit((data) => onSubmit(data, true))}
                    variant="contained"
                  >
                    Сохранить черновик
                  </Button>
                </Tooltip>
                <Button
                  onClick={handleSubmit((data) => onSubmit(data, false))}
                  variant="outlined"
                >
                  Сохранить и отправить на согласование
                </Button>
                <Button color="primary" onClick={handleBack} variant="text">
                  Назад к списку
                </Button>
              </Stack>
              <Button
                color="primary"
                onClick={handleReset}
                sx={{ ml: 1 }}
                variant="text"
              >
                Сбросить
              </Button>
            </Stack>
          </form>
        </FormProvider>
        <BlockFormModal when={isDirty && !isSubmitSuccessful} />
      </Card>
    </>
  );
};

export default MyCompensationAdd;
