import { useCallback, useEffect, useState } from "react";

import { Card, Grid, IconButton, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import CustomViewField from "../../components/CustomViewField";
import MaterialTablePagination from "../../components/MaterialTablePagination";
import { TableCellsWrap } from "../../components/TableCellsWrap";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";
import { TitleStack } from "../../theme/standarts_styles";
import { getSortingString } from "../../utils/getSortingString";

const UserContractsView = () => {
  const tableId = "userContracts";
  const tableStates = useSelector((state) => state.tableStatesReducer);

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { contractId } = useParams();
  const { selectedContract, contracts } = useSelector(
    (state) => state.contractsReducer,
  );
  const { setContracts, setSelectedContract } = useActions();
  const { roles } = useSelector((state) => state.authReducer);

  const [searchText, setSearchText] = useState(
    tableStates[tableId]?.globalFilter || "",
  );
  const [pagination, setPagination] = useState({
    pageIndex: tableStates[tableId]?.pagination?.pageIndex || 0,
    pageSize: tableStates[tableId]?.pagination?.pageSize || 20,
  });
  const [sorting, setSorting] = useState(tableStates[tableId]?.sorting || []);
  const [columnFilters, setColumnFilters] = useState(
    tableStates[tableId]?.columnFilters || [],
  );
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(-1);

  const tableColumns = [
    {
      accessorKey: "start_date",
      header: "Дата заключения",
      accessorFn: (row) => dayjs(row.start_date).format("DD.MM.YYYY") || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "end_date",
      header: "Дата окончания",
      accessorFn: (row) => dayjs(row.end_date).format("DD.MM.YYYY") || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "status",
      header: "Состояние",
      accessorFn: (row) => row.status || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "type",
      header: "Тип",
      accessorFn: (row) => row.type || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "legal_entity",
      header: "Юр. лицо",
      accessorFn: (row) => row.legal_entity || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "date_and_number",
      header: "Дата и номер",
      accessorFn: (row) => row.date_and_number || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "interaction_capacity",
      header: "Емкость взаимодействия",
      accessorFn: (row) => row.interaction_capacity || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "hour_capacity",
      header: "Емкость часов fix",
      accessorFn: (row) => row.hour_capacity || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "loyalty_program",
      header: "Программа лояльности",
      accessorFn: (row) => row.loyalty_program || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "production_calendar",
      header: "Производственный календарь",
      accessorFn: (row) => row.production_calendar || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    ...(roles?.some((role) => role === "ERP_Admins")
      ? [
          {
            accessorKey: "month_rate",
            header: "Ставка мес.",
            accessorFn: (row) => row.month_rate || "",
            Cell: ({ renderedCellValue, row }) => (
              <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
            ),
          },
          {
            accessorKey: "hour_rate",
            header: "Час. ставка",
            accessorFn: (row) => row.hour_rate || "",
            Cell: ({ renderedCellValue, row }) => (
              <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
            ),
          },
        ]
      : []),
    {
      accessorKey: "vacation_days",
      header: "Отпуск в днях в год",
      accessorFn: (row) => row.vacation_days || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "sick_days",
      header: "Больничные в год",
      accessorFn: (row) => row.sick_days || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
  ];

  const handleOpenViewPage = (row) => {
    const id = row.original.id;
    if (id) {
      setSelectedContract(id);
    }
  };

  const handleBack = () => {
    navigate("/employee-relations/contracts");
  };

  const handleEdit = () => {
    navigate(`/employee-relations/contracts/${contractId}/edit`);
  };

  const onGlobalFilterChange = (v) => {
    setSearchText(v);
  };

  const loadData = useCallback(async () => {
    setLoading(true);

    try {
      const sortStr = getSortingString(sorting);

      const response = await $authHost.get(`/agreements/all/`, {
        params: {
          user_id: selectedContract.user_id,
          page: pagination.pageIndex + 1,
          size: pagination.pageSize,
          ...(searchText && { search: searchText }),
          ...(sortStr && { sort_by: sortStr }),
          ...columnFilters.reduce(
            (acc, column) => ({
              ...acc,
              ...{ [`${column.id}_text`]: column.value },
            }),
            {},
          ),
        },
      });

      setContracts(response.data.items);
      setRowCount(response.data?.total);
      setPageCount(response.data?.pages);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [
    columnFilters,
    pagination.pageIndex,
    pagination.pageSize,
    searchText,
    selectedContract.user_id,
    setContracts,
    sorting,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      <TitleStack>
        <Typography sx={{ my: 2 }} variant="h5">
          Договорные отношения ({selectedContract.user?.display_name})
        </Typography>
      </TitleStack>
      <Card sx={{ p: 3 }}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          sx={{ mb: 3 }}
        >
          <Grid item>
            <Typography color="text.secondary" variant="subtitle1">
              Действующий договор
            </Typography>
          </Grid>
          {/*<Grid item>*/}
          {/*  <IconButton*/}
          {/*    onClick={handleBack}*/}
          {/*    sx={{p: 0}}*/}
          {/*  >*/}
          {/*    <CloseIcon />*/}
          {/*  </IconButton>*/}
          {/*</Grid>*/}
        </Grid>
        <CustomViewField
          label="Номер договора"
          text={selectedContract.date_and_number}
        />
        <CustomViewField
          label="Дата заключения"
          text={dayjs(selectedContract.start_date).format("DD.MM.YYYY")}
        />
        <CustomViewField
          label="Дата окончания"
          text={
            selectedContract.is_unlimited
              ? "Бессрочный"
              : dayjs(selectedContract.end_date).format("DD.MM.YYYY")
          }
        />
        <CustomViewField
          label="Статус договора"
          text={selectedContract.status}
        />
        <CustomViewField label="Тип договора" text={selectedContract.type} />
        <CustomViewField
          label="Юр. лицо"
          text={selectedContract.legal_entity}
        />
        <CustomViewField
          label="Производственный календарь"
          text={selectedContract.production_calendar}
        />
        <CustomViewField
          label="Программа лояльности"
          text={selectedContract.loyalty_program}
        />
        <CustomViewField
          label="Вид занятости"
          text={selectedContract.interaction_capacity}
        />
        {selectedContract.interaction_capacity === "Fix" && (
          <>
            <CustomViewField
              label="Объем часов Fix"
              text={selectedContract.hour_capacity}
            />
            {roles?.some((role) => role === "ERP_Admins") && (
              <CustomViewField
                label="Ставка в час"
                text={selectedContract.hour_rate}
              />
            )}
          </>
        )}
        {roles?.some((role) => role === "ERP_Admins") &&
          selectedContract.interaction_capacity === "Fulltime" && (
            <CustomViewField
              label="Ставка в месяц"
              text={selectedContract.month_rate}
            />
          )}
        {roles?.some((role) => role === "ERP_Admins") && (
          <CustomViewField
            label="Валюта договора"
            text={selectedContract.currency}
          />
        )}
        <CustomViewField
          label="Отпуск в год, дней"
          text={selectedContract.vacation_days}
        />
        <CustomViewField
          label="Больничные в год, дней"
          text={selectedContract.sick_days}
        />
        <Stack direction="row" spacing={1}>
          <Button onClick={handleBack} variant="contained">
            Назад к списку
          </Button>
          {roles?.some((role) => role === "ERP_Admins") && (
            <Button color="primary" onClick={handleEdit} variant="text">
              Редактировать
            </Button>
          )}
        </Stack>
      </Card>
      <TitleStack>
        <Typography sx={{ my: 2 }} variant="h5">
          Все договорные отношения ({selectedContract.user?.display_name})
        </Typography>
      </TitleStack>
      <Card sx={{ pt: 1 }}>
        <MaterialTablePagination
          columnFilters={columnFilters}
          columns={tableColumns}
          data={contracts}
          enableHiding={true}
          id={tableId}
          loading={loading}
          muiTableBodyCellProps={({ row }) => ({
            onClick: () => {
              handleOpenViewPage(row);
            },
            sx: {
              cursor: "pointer",
            },
          })}
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={onGlobalFilterChange}
          onSortingChange={setSorting}
          pageCount={pageCount}
          pagination={pagination}
          rowCount={rowCount}
          search={searchText}
          setPagination={setPagination}
          sorting={sorting}
        />
      </Card>
    </>
  );
};

export default UserContractsView;
