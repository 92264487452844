import { useCallback, useEffect, useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Card,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Iconify from "../../components/iconify";
import MaterialTablePagination from "../../components/MaterialTablePagination";
import { TableCellsWrap } from "../../components/TableCellsWrap";
import { useActions } from "../../hook/useActions";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import { getSortingString } from "../../utils/getSortingString";

const ContractsList = () => {
  const tableId = "contractsList";
  const tableStates = useSelector((state) => state.tableStatesReducer);
  const isMobile = useResponsive("down", "md");

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setContracts, setSelectedContract } = useActions();
  const { contracts } = useSelector((state) => state.contractsReducer);
  const { roles } = useSelector((state) => state.authReducer);

  const [searchText, setSearchText] = useState(
    tableStates[tableId]?.globalFilter || "",
  );
  const [pagination, setPagination] = useState({
    pageIndex: tableStates[tableId]?.pagination?.pageIndex || 0,
    pageSize: tableStates[tableId]?.pagination?.pageSize || 20,
  });
  const [sorting, setSorting] = useState(tableStates[tableId]?.sorting || []);
  const [columnFilters, setColumnFilters] = useState(
    tableStates[tableId]?.columnFilters || [],
  );
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(-1);

  const [showExpired, setShowExpired] = useState(false);

  const tableColumns = [
    {
      accessorKey: "user.display_name",
      header: "Сотрудник",
      accessorFn: (row) => row.user?.display_name || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "date_and_number",
      header: "Номер договора",
      accessorFn: (row) => row.date_and_number || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "start_date",
      header: "Дата заключения",
      accessorFn: (row) => dayjs(row.start_date).format("DD.MM.YYYY") || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "end_date",
      header: "Дата окончания",
      accessorFn: (row) => {
        if (row.is_unlimited) {
          return "Бессрочный";
        } else {
          return dayjs(row.end_date).format("DD.MM.YYYY") || "";
        }
      },
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "status",
      header: "Статус договора",
      accessorFn: (row) => row.status || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "type",
      header: "Тип договора",
      accessorFn: (row) => row.type || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "legal_entity",
      header: "Юр. лицо",
      accessorFn: (row) => row.legal_entity || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "production_calendar",
      header: "Производственный календарь",
      accessorFn: (row) => row.production_calendar || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "loyalty_program",
      header: "Программа лояльности",
      accessorFn: (row) => row.loyalty_program || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "interaction_capacity",
      header: "Вид занятости",
      accessorFn: (row) => row.interaction_capacity || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "hour_capacity",
      header: "Объем часов Fix",
      accessorFn: (row) => {
        if (row.interaction_capacity === "Fix" && row.hour_capacity) {
          return row.hour_capacity;
        } else {
          return "";
        }
      },
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    ...(roles?.some((role) => role === "ERP_Admins")
      ? [
          {
            accessorKey: "hour_rate",
            header: "Ставка в час",
            accessorFn: (row) => {
              if (row.interaction_capacity === "Fix" && row.hour_rate) {
                return row.hour_rate;
              } else {
                return "";
              }
            },
            Cell: ({ renderedCellValue, row }) => (
              <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
            ),
          },
          {
            accessorKey: "month_rate",
            header: "Ставка в месяц",
            accessorFn: (row) => {
              if (row.interaction_capacity === "Fulltime" && row.month_rate) {
                return row.month_rate;
              } else {
                return "";
              }
            },
            Cell: ({ renderedCellValue, row }) => (
              <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
            ),
          },
          {
            accessorKey: "currency",
            header: "Валюта договора",
            accessorFn: (row) => row.currency || "",
            Cell: ({ renderedCellValue, row }) => (
              <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
            ),
          },
        ]
      : []),
    {
      accessorKey: "vacation_days",
      header: "Отпуск в год, дней",
      accessorFn: (row) => row.vacation_days || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "sick_days",
      header: "Больничный в год, дней",
      accessorFn: (row) => row.sick_days || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "actions",
      size: 1,
      enableColumnFilter: false,
      enableSorting: false,
      enableHiding: false,
      muiTableBodyCellProps: {
        onClick: (e) => {},
        sx: {
          cursor: "auto",
        },
      },
      Cell: ({ renderedCellValue, row }) => {
        return (
          <Tooltip title="Редактировать">
            <IconButton
              color="inherit"
              onClick={() => handleEditRow(row.original.id)}
              size="large"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const handleEditRow = (id) => {
    navigate(`/employee-relations/contracts/${id}/edit`);
    setSelectedContract(id);
  };

  const handleOpenViewPage = (row) => {
    const id = row.original.id;
    if (id) {
      navigate(`/employee-relations/contracts/${id}`);
      setSelectedContract(id);
    }
  };

  const handleAdd = () => {
    navigate(`/employee-relations/contracts/new`);
  };

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const sortStr = getSortingString(sorting);

      const response = await $authHost.get(`/agreements/all/`, {
        params: {
          page: pagination.pageIndex + 1,
          size: pagination.pageSize,
          ...(searchText && { search: searchText }),
          ...(sortStr && { sort_by: sortStr }),
          ...columnFilters.reduce(
            (acc, column) => ({
              ...acc,
              ...{ [`${column.id}_text`]: column.value },
            }),
            {},
          ),
          ...(!showExpired && { status: "Действующий" }),
        },
      });

      setRowCount(response.data?.total);
      setPageCount(response.data?.pages);
      setContracts(response.data.items);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }, [
    columnFilters,
    pagination.pageIndex,
    pagination.pageSize,
    searchText,
    setContracts,
    showExpired,
    sorting,
  ]);

  const onGlobalFilterChange = (v) => {
    setSearchText(v);
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Box>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ height: "70px" }}
      >
        {roles?.some((role) => role === "ERP_Admins") && (
          <Button
            onClick={handleAdd}
            size={isMobile ? "small" : "medium"}
            startIcon={<Iconify icon="eva:plus-fill" />}
            variant="contained"
          >
            Новый договор
          </Button>
        )}
        <FormControlLabel
          control={
            <Switch
              checked={showExpired}
              // size={isMobile ? 'small' : 'medium'}
              onChange={(e) => setShowExpired(e.target.checked)}
            />
          }
          label={
            <Typography variant={isMobile ? "body2" : "body1"}>
              Показать недействующие
            </Typography>
          }
          sx={{ m: 0 }}
        />
      </Stack>
      <Card sx={{ pt: 1 }}>
        <MaterialTablePagination
          columnFilters={columnFilters}
          columns={tableColumns}
          data={contracts}
          enableHiding={true}
          id={tableId}
          initialState={{
            columnVisibility: {
              date_and_number: false,
              type: false,
              legal_entity: false,
              hour_rate: false,
              month_rate: false,
              currency: false,
              vacation_days: false,
              sick_days: false,
            },
          }}
          loading={loading}
          muiTableBodyCellProps={({ row }) => ({
            onClick: () => {
              handleOpenViewPage(row);
            },
            sx: {
              cursor: "pointer",
            },
          })}
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={onGlobalFilterChange}
          onSortingChange={setSorting}
          pageCount={pageCount}
          pagination={pagination}
          rowCount={rowCount}
          search={searchText}
          setPagination={setPagination}
          sorting={sorting}
        />
      </Card>
    </Box>
  );
};

export default ContractsList;
