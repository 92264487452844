import { authActions } from "./slice/auth.slice";
import { budgetsActions } from "./slice/budgets.slice";
import { calendarActions } from "./slice/calendar.slice";
import { clientsActions } from "./slice/clients.slice";
import { contactsActions } from "./slice/contacts.slice";
import { contractsActions } from "./slice/contracts.slice";
import { departmentsActions } from "./slice/departments.slice";
import { employeesActions } from "./slice/employees.slice";
import { paymentsActions } from "./slice/payment.slice";
import { planningActions } from "./slice/planning.slice";
import { projectsActions } from "./slice/projects.slice";
import { scheduleActions } from "./slice/schedule.slice";
import { serviceActions } from "./slice/service.slice";
import { statisticsActions } from "./slice/statistics.slice";
import { tableStatesActions } from "./slice/tableStates.slice";
import { timesheetsActions } from "./slice/timesheets.slice";
import { usersActions } from "./slice/users.slice";

export const rootActions = {
  ...projectsActions,
  ...employeesActions,
  ...clientsActions,
  ...contactsActions,
  ...budgetsActions,
  ...departmentsActions,
  ...authActions,
  ...scheduleActions,
  ...planningActions,
  ...calendarActions,
  ...serviceActions,
  ...timesheetsActions,
  ...tableStatesActions,
  ...contractsActions,
  ...usersActions,
  ...statisticsActions,
  ...paymentsActions,
  logOut: () => (dispatch) => {
    dispatch(authActions.logOut());
    dispatch(projectsActions.logOut());
    dispatch(clientsActions.logOut());
    dispatch(contactsActions.logOut());
    dispatch(departmentsActions.logOut());
    dispatch(employeesActions.logOut());
    dispatch(budgetsActions.logOut());
    dispatch(scheduleActions.logOut());
    dispatch(planningActions.logOut());
    dispatch(calendarActions.logOut());
    dispatch(timesheetsActions.logOut());
    dispatch(tableStatesActions.logOut());
    dispatch(contractsActions.logOut());
    dispatch(usersActions.logOut());
    dispatch(statisticsActions.logOut());
    dispatch(paymentsActions.logOut());
    dispatch(serviceActions.resetAllAlertMessage());
  },
};
