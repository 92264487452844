import { useState } from "react";

import DoneIcon from "@mui/icons-material/Done";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import SkeletonInput from "./SkeletonInput";
import { $authHost } from "../http";

const CustomViewField = ({
  label,
  text,
  loading = false,
  sx = {},
  type = "text",
  path = "",
  copyButton = false,
  files = [],
}) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    const textToCopy = renderText();
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopied(true);
      })
      .catch((error) => {
        console.error("Ошибка копирования в буфер обмена: ", error);
      });
  };
  const renderText = () => {
    if (text && (typeof text === "string" || typeof text === "number")) {
      return text;
    }
    return "Не найдено";
  };

  const downloadFile = async (id, name) => {
    try {
      const response = await $authHost.get(
        `/compensation/download_file/${id}/`,
        { responseType: "blob" },
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      console.log(e);
    }
  };

  if (type === "link") {
    return (
      <>
        <Typography variant="caption">{label}</Typography>
        <SkeletonInput loading={loading}>
          <Link target="_blank" to={path}>
            <Typography
              sx={{
                mb: 2,
                ...sx,
              }}
            >
              {renderText()}
            </Typography>
          </Link>
        </SkeletonInput>
      </>
    );
  }

  if (type === "text") {
    return (
      <>
        <Typography variant="caption">{label}</Typography>
        <Stack
          alignItems="center"
          direction="row"
          sx={{
            pb: 2,
          }}
        >
          <SkeletonInput loading={loading}>
            <Typography
              sx={{
                pr: 1,
                ...sx,
              }}
            >
              {renderText()}
            </Typography>
          </SkeletonInput>
          {copyButton && !copied && (
            <Tooltip title="Копировать">
              <IconButton
                color="inherit"
                disabled={
                  !(
                    text &&
                    (typeof text === "string" || typeof text === "number")
                  )
                }
                onClick={() => copyToClipboard()}
                size="small"
              >
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
          )}
          {copyButton && copied && (
            <IconButton
              color="inherit"
              onClick={() => copyToClipboard()}
              size="small"
            >
              <DoneIcon />
            </IconButton>
          )}
        </Stack>
      </>
    );
  }

  if (type === "file") {
    return (
      <>
        <Typography variant="caption">{label}</Typography>
        <Stack
          direction="column"
          sx={{
            pb: 2,
          }}
        >
          {Array.isArray(files) && files.length > 0
            ? files.map((file) => (
                <Box
                  key={file.id}
                  mt={1}
                  onClick={() => downloadFile(file.id, file.display_name)}
                  p={1}
                  // TODO: change color?
                  sx={{
                    border: "1px solid #dce0e4",
                    borderRadius: 1,
                    cursor: "pointer",
                    "&:hover": { borderColor: "#000000" },
                  }}
                >
                  <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                    spacing={2}
                  >
                    <Stack alignItems="center" direction="row" spacing={1}>
                      <FilePresentIcon />
                      <Typography>{file.display_name}</Typography>
                    </Stack>
                  </Stack>
                </Box>
              ))
            : "Не найдено"}
        </Stack>
      </>
    );
  }

  return <></>;
};

CustomViewField.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string,
  loading: PropTypes.bool,
  sx: PropTypes.object,
  type: PropTypes.string,
  path: PropTypes.string,
  copyButton: PropTypes.bool,
  files: PropTypes.array,
};

export default CustomViewField;
