import { useCallback, useEffect, useRef, useState } from "react";

import { Avatar, Button, Card, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AddEditFormButtonGroup from "./forms/AddEditFormButtonGroup";
import BaseAddEditForm from "./forms/BaseAddEditForm";
import LeaveApplicationFields from "./forms/formFields/LeaveApplicationFields";
import LeaveApplicationTopFields from "./forms/formFields/LeaveApplicationTopFields";
import Iconify from "./iconify";
import Label from "./label/Label";
import MaterialTablePagination from "./MaterialTablePagination";
import { TableCellsWrap } from "./TableCellsWrap";
import {statuses} from "../constants";
import { useActions } from "../hook/useActions";
import useResponsive from "../hook/useResponsive";
import { $authHost } from "../http";
import { ButtonStack } from "../theme/standarts_styles";
import { getSortingString } from "../utils/getSortingString";
import { scrollToTop } from "../utils/scrollToTop";

const statusOptions = [
  { name: "Отказано", original_name: "rejected" },
  { name: "Согласованно", original_name: "approved" },
];

const ApproveSchedule = ({ id, admin }) => {
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState("");
  const fieldsRef = useRef(null);
  const [confirmation, setConfirmation] = useState(false);
  const isMobile = useResponsive("down", "md");

  const tableId = `ApproveSchedule${id}`;
  const tableStates = useSelector((state) => state.tableStatesReducer);

  const { userId, roles } = useSelector((state) => state.authReducer);

  const [searchText, setSearchText] = useState(
    tableStates[tableId]?.globalFilter || "",
  );
  const [pagination, setPagination] = useState({
    pageIndex: tableStates[tableId]?.pagination?.pageIndex || 0,
    pageSize: tableStates[tableId]?.pagination?.pageSize || 20,
  });
  const [sorting, setSorting] = useState(tableStates[tableId]?.sorting || []);
  const [columnFilters, setColumnFilters] = useState(
    tableStates[tableId]?.columnFilters || [],
  );
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(-1);

  const navigate = useNavigate();

  const { setCalendarRules, setSelectedContact, setSelectedStatement } =
    useActions();

  const { calendar_rules } = useSelector((state) => state.calendarReducer);

  const getColorByStatus = (renderedCellValue) => {
    switch (renderedCellValue) {
      case "approved":
        return "success";
      case "rejected":
        return "error";
      case "new":
        return "info";
      default:
        return "default";
    }
  };

  const types = {
    vacation: "Отпуск",
    day_off: "Отгул",
    sick_leave: "Больничный",
    overtime_work_days: "Сверхурочные часы",
    fired: "Увольнение",
  };

  // const statuses = {
  //   new: "Новое",
  //   approved: "Согласовано",
  //   rejected: "Отклонено",
  //   cancelled: "Аннулировано",
  //   recalled: "Отозвано",
  // };

  const tableColumns = [
    {
      accessorKey: "updated_at",
      header: "Последние изменения",
      accessorFn: (row) => dayjs(row.updated_at).format("DD.MM.YYYY") || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "status",
      header: "Статус",
      accessorFn: (row) => statuses[row.status] || "",
      Cell: ({ renderedCellValue, row }) => {
        return (
          <TableCellsWrap>
            {renderedCellValue ? (
              <Label color={getColorByStatus(row.original.status)}>
                {renderedCellValue}
              </Label>
            ) : (
              <Label color="default">Не указано</Label>
            )}
          </TableCellsWrap>
        );
      },
    },
    {
      accessorKey: "type",
      header: "Тип заявки",
      accessorFn: (row) => types[row.type] || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "user.display_name",
      header: "Сотрудник",
      accessorFn: (row) =>
        row.users
          .map(
            (user) =>
              user?.display_name || `${user?.first_name} ${user?.last_name}`,
          )
          .join(", "),
      Cell: ({ renderedCellValue, row }) => {
        if (Array.isArray(row.original?.users)) {
          return (
            <TableCellsWrap>
              <Stack alignItems="flex-start" direction="column" spacing={1}>
                {row.original.users.map((user) => (
                  <Chip
                    avatar={<Avatar src={user?.photo} />}
                    key={user.source_id}
                    label={
                      user?.display_name ||
                      `${user?.first_name} ${user?.last_name}`
                    }
                    variant="outlined"
                  />
                ))}
              </Stack>
            </TableCellsWrap>
          );
        }
      },
    },
    {
      id: "department.name",
      header: "Отдел",
      accessorFn: (row) => row.department?.name || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "start_date",
      header: "Дата начала",
      accessorFn: (row) => dayjs(row.start_date).format("DD.MM.YYYY") || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "end_date",
      header: "Дата окончания",
      accessorFn: (row) => row,
      Cell: ({ renderedCellValue }) => {
        switch (renderedCellValue.type) {
          case "fired": {
            return <TableCellsWrap>{""}</TableCellsWrap>;
          }
          default:
            return (
              <TableCellsWrap>
                {dayjs(renderedCellValue.end_date).format("DD.MM.YYYY") || ""}
              </TableCellsWrap>
            );
        }
      },
    },
    {
      id: "request_text",
      header: "Текст запроса",
      accessorFn: (row) => row.request_text || "",
      // accessorFn: (row) => dayjs(row.end_date).format('DD.MM.YYYY') || '',
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },

    {
      accessorKey: "user_approved.display_name",
      header: "Кто решил",
      accessorFn: (row) =>
        row.user_approved?.display_name ||
        `${row.user_approved?.first_name} ${row.user_approved?.last_name}`,
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>
          <Chip
            avatar={
              <Avatar
                alt={
                  row.original.user_approved?.display_name.substring(0, 1) ||
                  (row.original.user_approved &&
                    row.original.user_approved?.first_name.substring(0, 1)) ||
                  null
                }
                src={row.original.user_approved?.photo}
              />
            }
            label={
              row.original.user_approved?.display_name ||
              (row.original.user_approved &&
                `${row.original.user_approved?.first_name} ${row.original.user_approved?.last_name}`) ||
              null
            }
            variant="outlined"
          />
        </TableCellsWrap>
      ),
    },
    {
      id: "comment",
      header: "Комментарий",
      accessorFn: (row) => row?.comment || "",
      // accessorFn: (row) => dayjs(row.end_date).format('DD.MM.YYYY') || '',
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "created_at",
      header: "Дата подачи",
      accessorFn: (row) => dayjs(row.created_at).format("DD.MM.YYYY") || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
  ];

  const handleAdd = () => {
    setOpenForm((prevState) => {
      return prevState === "Add" ? "" : "Add";
    });
    scrollToTop();
    setSelectedRow(null);
  };
  const handleOpenViewPage = (row) => {
    setSelectedStatement(row.original.id);
    setSelectedRow(row.original);
    setSelectedContact(row.id);
    // openForm !== 'View' && setOpenForm('View')
    // scrollToTop()

    admin && navigate(`/schedule/statements-approval/${row.original.id}`);
    !admin && navigate(`/schedule/my-statements/${row.original.id}`);
  };

  const AddAction = async (data) => {
    const { type, payer, start_date, end_date, users, request_text } = data;
    switch (type === "fired" && roles?.some((role) => role === "ERP_Admins")) {
      case true:
        {
          let data1 = {
            calendar_rules_data: {
              status: "approved",
              subtype: "claim",
              type: type || null,
              start_date: dayjs(start_date).format("YYYY-MM-DD") || null,
              end_date: dayjs(start_date).format("YYYY-MM-DD") || null,
              users: [users?.source_id] || null,
              request_text: request_text || null,
            },
          };
          try {
            await $authHost.post(
              `/calendar_rules/add/?accept_to_all=false`,
              data1,
            );
            loadData();
            setOpenForm("");
          } catch (e) {
            console.log(e);
          }
        }
        break;
      default:
        {
          let new_data = {
            type: type || null,
            payer: payer || null,
            start_date: dayjs(start_date).format("YYYY-MM-DD") || null,
            end_date: end_date
              ? dayjs(end_date).format("YYYY-MM-DD")
              : dayjs(start_date).format("YYYY-MM-DD"),
            request_text: request_text || null,
          };
          try {
            await $authHost.post(`/calendar_rules/me/add/`, new_data);
            loadData();
            setOpenForm("");
          } catch (e) {
            console.log(e);
          }
        }
        break;
    }
  };

  const ViewAction = async (data) => {
    const { status, comment, id } = data;
    try {
      await $authHost.patch(`/calendar_rules/claim/${id}/update/`, {
        status,
        comment,
      });
      loadData();
      setOpenForm("");
    } catch (e) {
      console.log(e);
    }
  };

  const onGlobalFilterChange = (v) => {
    setSearchText(v);
  };

  const loadData = useCallback(async () => {
    setLoading(true);

    try {
      const sortStr = getSortingString(sorting);

      const response = await $authHost.get(
        id === 1 ? `/calendar_rules/me/` : `/calendar_rules/all/`,
        {
          params: {
            subtype: "claim",
            page: pagination.pageIndex + 1,
            size: pagination.pageSize,
            ...(searchText && { search: searchText }),
            ...(sortStr && { sort_by: sortStr }),
            ...columnFilters.reduce(
              (acc, column) => ({
                ...acc,
                ...{ [`${column.id}_text`]: column.value },
              }),
              {},
            ),
          },
        },
      );

      const data = response.data.items;
      setCalendarRules(data);
      setRowCount(response.data?.total);
      setPageCount(response.data?.pages);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [
    columnFilters,
    id,
    pagination.pageIndex,
    pagination.pageSize,
    searchText,
    setCalendarRules,
    sorting,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Box pt={admin ? 2 : 0}>
      {!admin && (
        <ButtonStack>
          <Button
            aria-haspopup="true"
            onClick={handleAdd}
            size={isMobile ? "small" : "medium"}
            startIcon={<Iconify icon="eva:plus-fill" />}
            sx={{ mr: 3 }}
            variant="contained"
          >
            Создать заявление
          </Button>
        </ButtonStack>
      )}
      {/*<AlertMessage/>*/}
      {openForm && (
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
            <Card sx={{ p: 3, mb: 2 }}>
              <BaseAddEditForm
                AddAction={AddAction}
                ViewAction={ViewAction}
                action={openForm}
                fieldsRef={fieldsRef}
                handleClose={() => setOpenForm("")}
                selectedRow={selectedRow}
                setConfirmation={setConfirmation}
                setLoading={setLoading}
              >
                <LeaveApplicationFields openForm={openForm} />
                {openForm === "View" && (
                  <LeaveApplicationTopFields
                    loading={loading}
                    selectedClient={selectedRow}
                    statusOptions={statusOptions}
                  />
                )}
                <AddEditFormButtonGroup
                  action={openForm}
                  confirmation={confirmation}
                  handleClose={() => setOpenForm("")}
                />
              </BaseAddEditForm>
            </Card>
          </Grid>
        </Grid>
      )}
      <Card sx={{ pt: 1 }}>
        <MaterialTablePagination
          columnFilters={columnFilters}
          columns={tableColumns}
          data={calendar_rules}
          enableHiding={true}
          id={tableId}
          initialState={
            id === 1 && {
              columnVisibility: {
                user: false,
              },
            }
          }
          loading={loading}
          muiTableBodyCellProps={({ row }) => ({
            onClick: () => {
              handleOpenViewPage(row);
            },
            sx: {
              cursor: "pointer",
            },
          })}
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={onGlobalFilterChange}
          onSortingChange={setSorting}
          pageCount={pageCount}
          pagination={pagination}
          rowCount={rowCount}
          search={searchText}
          setPagination={setPagination}
          sorting={sorting}
        />
      </Card>
    </Box>
  );
};

export default ApproveSchedule;
